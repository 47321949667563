/* eslint-disable jsx-a11y/label-has-for */
import { clsx } from 'clsx';

import { fieldId, fieldName } from '../../lib/utils';
import ErrorHint from '../ErrorHint';
import HelpText from '../HelpText';

function Fields({
  base,
  fieldPath,
  question,
  destroy,
  getHumanIndex,
  allowRemove,
  translations,
}) {
  const index = getHumanIndex(question);

  const idId = fieldId(base, [...fieldPath, 'id']);
  const idName = fieldName(base, [...fieldPath, 'id']);

  const textId = fieldId(base, [...fieldPath, 'text']);
  const textName = fieldName(base, [...fieldPath, 'text']);
  const text = question.text || undefined;
  const textInvalid = question.errors.text;
  const textClass = clsx('form-control', {
    'is-invalid': textInvalid,
  });

  const helpTexts = question.help || undefined;
  const textHelpId = helpTexts ? fieldId(textId, ['help']) : undefined;

  const removeButton = allowRemove ? (
    <div className="">
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={() => destroy(question)}
      >
        {translations.remove_question}s
      </button>
    </div>
  ) : null;

  return (
    <fieldset className="card mb-3">
      <div className="card-header">
        <strong>{translations.open_question.replace('{index}', index)}</strong>
      </div>

      <div className="card-body">
        {question.id && (
          <input type="hidden" value={question.id} name={idName} id={idId} />
        )}

        <div className="form-group">
          <label htmlFor={textId}>
            <strong>Text</strong>
          </label>
          {helpTexts && <HelpText id={textHelpId} text={helpTexts.text} />}
          <textarea
            className={textClass}
            id={textId}
            name={textName}
            defaultValue={text}
            aria-describedby={textHelpId}
          />
          {textInvalid && <ErrorHint messages={question.errors.text} />}
        </div>

        {removeButton}
      </div>
    </fieldset>
  );
}

Fields.defaultProps = {
  question: {},
};

export default Fields;
