/* eslint-disable no-underscore-dangle */
import Fields from './Fields';
import DestroyFields from '../DestroyFields';

function OpenQuestion({
  index,
  question,
  destroy,
  getHumanIndex,
  base,
  fieldPath,
  allowRemove,
  translations,
}) {
  const markedForDestroy = question._destroy;
  const indexedFieldPath = [fieldPath, index];

  return markedForDestroy ? (
    <DestroyFields fieldPath={indexedFieldPath} question={question} />
  ) : (
    <Fields
      base={base}
      fieldPath={indexedFieldPath}
      question={question}
      destroy={destroy}
      getHumanIndex={getHumanIndex}
      allowRemove={allowRemove}
      translations={translations}
    />
  );
}

OpenQuestion.defaultProps = {
  question: {},
};

export default OpenQuestion;
